@import "../style/component_variables";

.ProgressBar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1rem;
  border-radius: 8px;
  overflow: hidden;

  .progress {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 0;
  }
}

// [data-theme="dark"] {
//   .ProgressBar {
//     background-color: rgba(#000, 0.3);
//   }
// }
