[data-theme='dark'] {
  color: #eee;
  background-image: none;
  background-color: #222;

  $gray-min: #111;
  $gray-max: #777;

  $gray-100: mix($gray-min, $gray-max, 10%);
  $gray-200: mix($gray-min, $gray-max, 20%);
  $gray-300: mix($gray-min, $gray-max, 30%);
  $gray-400: mix($gray-min, $gray-max, 40%);
  $gray-500: mix($gray-min, $gray-max, 50%);
  $gray-600: mix($gray-min, $gray-max, 60%);
  $gray-700: mix($gray-min, $gray-max, 70%);
  $gray-800: mix($gray-min, $gray-max, 80%);
  $gray-900: mix($gray-min, $gray-max, 90%);

  $white: $gray-min;

  .card {
    background-color: $gray-900;
    color: #eee;
    border-color: $gray-500;
    box-shadow: none;
    .card-header {
      border-color: $gray-500;
      background-color: rgba(black, 0.1);
    }
    .card-footer {
      border-color: $gray-500;
      background-color: rgba(black, 0.1);
    }
  }

  .card {
    background-color: $gray-900;
    color: #eee;
    border-color: $gray-500;
    box-shadow: none;
    .card-header {
      border-color: $gray-500;
      background-color: rgba(black, 0.1);
    }
    .card-footer {
      border-color: $gray-500;
      background-color: rgba(black, 0.1);
    }
  }

  .modal-content {
    background-color: $gray-900;
    .close {
      color: #eee !important;
    }
    .modal-header {
      border-color: $gray-500;
      background-color: $gray-600;
    }
    .modal-footer {
      border-color: $gray-500;
    }
  }

  .list-group {
    .list-group-item {
      background-color: $gray-800;
      color: #eee;
      border-color: $gray-500;
    }
  }

  .navbar-wrapper {
    box-shadow: none;
  }

  .custom-control-label::before {
    background-color: $white;
  }

  button.back-button {
    $background-color: $white;
    $icon-color: $gray-100;
    $darken-percentage: 5%;

    background-color: $background-color;
    border-color: $gray-500;
    color: $icon-color;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: darken($background-color, $darken-percentage);
      color: darken($icon-color, $darken-percentage);
      box-shadow: none;
    }
  }

  .site-content,
  .page {
    background-image: none;
    background-position: 0% 0%;
    background-size: 100% 300px;
    background-repeat: no-repeat no-repeat;
    // background: linear-gradient(180deg, white 0%, #f00 100%);
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: #eee;
      }
    }
  }

  .bg-gray100 {
    background-color: $gray-100 !important;
  }
  .bg-gray200 {
    background-color: $gray-200 !important;
  }
  .bg-gray300 {
    background-color: $gray-300 !important;
  }
  .bg-gray400 {
    background-color: $gray-400 !important;
  }
  .bg-gray500 {
    background-color: $gray-500 !important;
  }
  .bg-gray600 {
    background-color: $gray-600 !important;
  }
  .bg-gray700 {
    background-color: $gray-700 !important;
  }
  .bg-gray800 {
    background-color: $gray-800 !important;
  }
  .bg-gray900 {
    background-color: $gray-900 !important;
  }
  .bg-white {
    background-color: $gray-900 !important;
    color: #eee;
  }

  .card {
    box-shadow: none;
    .card-header {
      background-color: $gray-800;
    }
  }

  .form-control {
    background-color: $gray-600 !important;
    color: #eee !important;
    border-color: $gray-500 !important;
  }

  .dropdown-menu {
    background-color: $gray-700;
    border-color: $gray-500;
    color: #eee;
    box-shadow: none;
  }

  .dropdown-item {
    color: #eee;
    @include hover-focus() {
      color: #ddd;
      text-decoration: none;
      background-image: none;
      background-color: rgba(black, 0.2);
    }
  }

  .dropdown-divider {
    border-color: $gray-500;
  }

  .modal-backdrop {
    background-color: #000;
    &.show {
      opacity: 0.7;
    }
  }

  .table {
    color: #eee;
    tr,
    td,
    th {
      border-color: $gray-500;
    }
    &.table-hover {
      tbody tr {
        @include hover() {
          color: #ddd !important;
          background-color: rgba(white, 0.1);
        }
      }
    }
  }

  $input-disabled-bg: $gray-700;
  $input-bg: $gray-600;
  $input-focus-bg: $input-bg;
  $input-color: #eee;
  $input-border-color: $gray-500;
  $input-focus-border-color: $gray-500;
  @import "./_react_select2_bootstrap";
}
