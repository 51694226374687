@import '../style/component_variables';

.loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(#fff, 0.5);
  &.fade-on-visible {
    opacity: 0;
    transition: opacity $transition-time;

    &.visible {
      opacity: 1;
    }
  }
  z-index: 1;
}

[data-theme="dark"] {
  .loading-overlay {
    background-color: rgba(#000, 0.3);
  }
}
