@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i|Anton:400&display=swap");

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

@import "./style/_variables";

@import "~bootstrap/scss/bootstrap";

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-image: url("./media/background.jpg");
  background-size: 256px 256px;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: color-yiq($value);

    @if not is-dark($value) {
      text-shadow: 0.0625rem 0.0625rem 0.125rem rgba($black, 0.5);
    }

    box-shadow: 0 0.25rem 0 0 darken($value, 7%),
      0 0.25rem 0.56rem rgba(0, 0, 0, 0.75);

    &:hover:not(:disabled) {
      transform: translate(0, 0.15rem);
      box-shadow: 0 0.25rem 0 0 darken($value, 7%),
        0 0.25rem 0.56rem rgba(0, 0, 0, 0.3);

      @if not is-dark($value) {
        text-shadow: 0.0625rem 0.0625rem 0.125rem rgba($black, 0.2);
      }

      // color: darken(color-yiq($value), 5%);
    }

    &:disabled {
      // box-shadow: 0 0.25rem 0 0 $value;
      box-shadow: 0 0.25rem 0 0 darken($value, 7%),
        0 0.25rem 0.56rem rgba(0, 0, 0, 0);
      text-shadow: none;
    }

    &,
    &:hover {
      border-color: transparent;
    }
  }
}

.btn {
  transition: transform $transition-time, box-shadow $transition-time,
    background-color $transition-time, border-width $transition-time,
    border-color $transition-time, color $transition-time,
    text-shadow $transition-time;
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  main {
    padding-bottom: 2rem;
    flex-grow: 1;
  }

  .copyright {
    color: $gray-900;
    font-size: 0.875rem;
    opacity: 0.7;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.breadcrumb-item {
  font-size: $h4-font-size;
  font-weight: $headings-font-weight;
}

.page-link {
  min-width: 3rem;
}

.pagination {
  justify-content: center;
}

.position-relative {
  position: relative;
}

.card {
  box-shadow: $shadow-lg;

  .card-header {
    background-color: $gray-100;
  }
}

.modal-header {
  background-color: $gray-100;
}

.navbar-full-width-col {
  flex: 0 0 100%;
  max-width: 100%;
}

@include media-breakpoint-up(sm) {
  .dropdown-menu {
    box-shadow: $shadow-lg;
  }
}

.navbar-wrapper {
  box-shadow: $shadow-lg;
}

.navbar {
  .nav-item {
    text-transform: uppercase;
    letter-spacing: 0.18em;
    font-weight: bold;
    font-size: 0.69rem;
  }

  .dropdown-item {
    text-transform: none;
    letter-spacing: normal;
    font-weight: normal;
  }
}

// h1,h2,h3,h4,h5,h6,
// .h1,.h2,.h3,.h4,.h5,.h6 {
//   // text-transform: uppercase;
// }

.fancy {
  font-family: Anton, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.card {
  // border-color: transparent;
}

.dropdown-menu {
  // border-color: transparent;
}

.button-spacer {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.custom-card-columns-2 {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: auto;

  > .card {
    display: inline-block;
    width: 50%;
  }
}

.zoom-on-hover {
  transition: transform $transition-time;
  display: block;

  &:hover {
    transform: scale(1.1, 1.1) rotateZ(0);
  }
}

.button-container {
  margin-right: -0.5rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

Ø .modal-dialog.no-background {
  .modal-content {
    background: none;
  }
}

.auto-card-group {
  > * {
    min-width: 100%;
  }
}

@each $size in (xs, sm, md, lg, xl) {
  .auto-card-group-#{$size}-2 {
    > * {
      @include media-breakpoint-up($size) {
        min-width: 49%;
        max-width: 49%;
      }

      &.full-width-card {
        @include media-breakpoint-down($size) {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .auto-card-group-#{$size}-3 {
    > * {
      @include media-breakpoint-up($size) {
        min-width: 32%;
        max-width: 32%;
      }

      &.full-width-card {
        @include media-breakpoint-down($size) {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .auto-card-group-#{$size}-4 {
    > * {
      @include media-breakpoint-up($size) {
        min-width: 24%;
        max-width: 24%;
      }

      &.full-width-card {
        @include media-breakpoint-down($size) {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.card-group-with-gaps {
  justify-content: space-between;

  > * {
    margin-bottom: $card-group-margin;
  }

  display: flex;
  flex-flow: row wrap;

  > * {
    flex: 1 0 0%;
  }
}

.video-loading-cover {
  opacity: 1;
  transition: all 1s;

  &.video-loaded {
    opacity: 0;
  }
}

button.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: $link-color;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $link-hover-color;
  }
}

.aspect-ratio {
  &.aspect-ratio-square {
    padding-top: 100%;
  }

  &.aspect-ratio-9by10 {
    padding-top: 111%;
  }

  &.aspect-ratio-9by11 {
    padding-top: 122%;
  }

  &.aspect-ratio-9by16 {
    padding-top: 177%;
  }

  position: relative;

  > * {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.App > main {
  > .watermark {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    min-height: 50vw;
    overflow: hidden;
    opacity: 0.1;
    z-index: -999;

    &::after {
      content: "";
      position: absolute;
      transform: rotateZ(-35deg) translate(-10%, 0);
      right: 30px;
      bottom: 30px;
      width: 50%;
      min-width: 400px;
      padding-top: 50%;
      background-image: url("./media/logo-brushed-white.png");
      background-size: contain;
      background-repeat: no-repeat no-repeat;
      background-position: 50% 100%;
      position: absolute;
    }
  }
}

.disable-selection {
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
}

table.table.table-hover tr.disabled:hover {
  background-color: inherit;

  td {
    background-color: inherit;
    opacity: 1;
  }
}

.TableScreen-no-records {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;

  > * {
    padding: 1.5rem;
    border-radius: $card-border-radius;
    background-color: $gray-100;
  }
}

.card-body.card-body-table {
  padding: 0;

  table.table {
    tr {
      th {
        border-top: none;
      }

      th,
      td {
        padding: 0.5rem 0.5rem;
      }

      @include media-breakpoint-up(sm) {
        td:first-child,
        th:first-child {
          padding-left: 1.5rem;
        }

        td:last-child,
        th:last-child {
          padding-right: 1.5rem;
        }
      }
    }
  }
}

:root {
  --animate-delay: 0.2s;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.input-group-append,
.input-group-prepend {
  .btn {
    &,
    &:hover,
    &:active,
    &:link,
    &:visited {
      box-shadow: none;
      transform: none;
      height: $input-height;
    }
  }
}
.input-group-append {
  .btn {
    &,
    &:hover,
    &:active,
    &:link,
    &:visited {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: $input-height;
      transform: none;
    }
  }
}

.input-group-prepend {
  .btn {
    &,
    &:hover,
    &:active,
    &:link,
    &:visited {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: $input-height;
      transform: none;
    }
  }
}

@import "./style/_react_select2_bootstrap";

// Make sure this is last
@import "./style/_dark_mode";
