// Variables

//
// Color system
//

// stylelint-disable
$gray-100: #f0f0f0;
$gray-200: #dddddd;
$gray-300: #cccccc;
$gray-400: #bbbbbb;
$gray-500: #aaaaaa;
$gray-600: #999999;
$gray-700: #666666;
$gray-800: #555555;
$gray-900: #444444;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

// $blue:      #6899fb;
$red: rgb(206, 7, 7);
// $yellow:    #FAB12B;
// $green:     #B4CD55;

// grey-cyan #d1e2e3;
// original theme cyan #1A7072
// cyan-border ##bfd7d8
// cyan-text #637070

$cyan: #1a7072;
$orange: #ce5f07;

$kabul: #66594f;
$quicksand: #c1a593;
$zorba: #9c9186;

$orange-bright: #ff7d1e;
$orange-medium: #9e4906;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $orange;
$secondary: #666666; // $gray-700;
$tertiary: #dddddd;
$success: $green;
$info: $cyan;
// $warning:       $yellow;
$danger: $red;
$light: $gray-100;
$dark: #333333;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'tertiary': $tertiary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'cyan': $cyan,
    'white': #fff,
  ),
  $theme-colors
);

// Switch to dark text a little later than the default threshold (150)

$yiq-text-dark: $gray-900;
$yiq-text-light: #fff;
$yiq-contrasted-threshold: 150;

@function if-dark($color, $dark, $light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $dark;
  } @else {
    @return $light;
  }
}

@function is-dark($color) {
  @return if-dark($color, true, false);
}

$enable-caret: true;

$spacer: 1.5rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    15: 15%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

$body-bg: $gray-100;
$body-color: #333333;

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

$component-active-bg: $primary;

$caret-width: 0.25em;

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// stylelint-enable value-keyword-case
$font-size-base: 0.875rem;
$font-weight-bold: 700;

$headings-margin-bottom: $spacer;
$headings-font-weight: 700;
$headings-line-height: 1.1;
// $headings-font-family:        'Anton', $font-family-sans-serif;

// $h1-font-size:                2.25rem;
// $h2-font-size:                1.875rem;
// $h3-font-size:                1.5rem;
// $h4-font-size:                1.125rem;
// $h5-font-size:                0.9375rem;
// $h6-font-size:                0.75rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);

$small-font-size: 0.75rem;

$text-muted: $gray-600;

$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $gray-200;

$list-inline-padding: 6px;

$table-cell-padding: 0.95rem;
$table-cell-padding-sm: 0.5rem;

$table-accent-bg: $gray-100;
$table-hover-bg: $gray-100;

$table-border-color: $gray-200;

$table-head-bg: $gray-100;
$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $gray-600;

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 0.9rem;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-font-weight: $font-weight-normal;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-link-disabled-color: $gray-500;

// $btn-border-radius:           .15rem;
// $btn-border-radius-lg:        .15rem;
// $btn-border-radius-sm:        .15rem;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-color: $body-color;
$input-border-width: 1px;
$input-border-color: $gray-300;
$input-border-radius: 0.25rem;

$input-focus-box-shadow: none;
$input-focus-border-color: darken($input-border-color, 10%);

$input-placeholder-color: $gray-500;

$input-height-border: $input-border-width * 2;

$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-group-addon-border-color: $input-border-color;

$custom-select-padding-y: 0.45rem;
$custom-select-padding-x: 0.9rem;
$custom-select-height: $input-height;

$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5);

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-btn-focus-box-shadow;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: none;

$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: none;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: 'Browse',
) !default;

$dropdown-padding-y: 0.25rem;
$dropdown-border-color: darken($light, 3%);

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $headings-color;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: lighten($light, 3%);

$dropdown-item-padding-y: 0.375rem;

$dropdown-header-color: inherit;

$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// $navbar-brand-font-size:            3rem;
$navbar-nav-link-padding-x: 0.8rem;
$navbar-light-color: $gray-900;
$navbar-light-hover-color: $orange;
$navbar-light-active-color: $orange;
$navbar-light-disabled-color: rgba($black, 0.3);

$pagination-color: $dark;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $dark;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$card-spacer-x: $spacer;
$card-border-color: $gray-300;
$card-cap-bg: $white;

$tooltip-border-radius: 0.2rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;

$popover-border-color: $gray-200;

$popover-header-bg: $gray-100;
$popover-header-padding-y: 0.7rem;
$popover-header-padding-x: 0.8rem;

$badge-font-weight: $font-weight-bold;

$modal-content-border-color: transparent;
$modal-content-border-radius: 0.2rem;
$modal-content-border-width: 0;
$modal-backdrop-bg: #000;
$modal-backdrop-opacity: 0.6;

$progress-bar-bg: theme-color('primary');

$list-group-border-color: $gray-200;
$list-group-active-bg: $component-active-bg;

$breadcrumb-padding-y: $spacer/1.5;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: transparent;
// $breadcrumb-divider:                quote("\F142");
$breadcrumb-divider: '/';
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-500;

$close-text-shadow: none;
$close-font-size: $font-size-base * 1.5;

$code-color: $cyan;

//---------------------------------------------------------------------------

//
// custom-variables.scss
//
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

$shadow-lg: 0 0 45px 0 rgba($kabul, 0.3);
$shadow-sm: 0 0.1rem 0.5rem 0 rgba($kabul, 0.4);
$shadow-xs: 0 0.1rem 0.3rem 0 rgba($kabul, 0.2);

$bg-leftbar: $dark; //linear-gradient(70deg,$green, $red);
$bg-topnav: $dark; //linear-gradient(70deg, $green, $red);

//Menu item colors (Default-dark)
$menu-item: #cedce4;
$menu-item-hover: #ffffff;
$menu-item-active: #ffffff;

//Menu item colors (light)
$menu-item-light: #1e83bb;
$menu-item-light-hover: #57bbbc;
$menu-item-light-active: #57bbbc;

//Topbar Background
$bg-topbar: #ffffff;

// Font weight
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

// This doesn't seem to inherit the colour correctly
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-active-color: $primary;
$custom-control-indicator-active-bg: rgba($primary, 0.7);
$custom-control-indicator-active-border-color: $primary;

$transition-time: 200ms;
