.TableScreen-card {
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0.5rem;
  }
  .filters-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    .filter-container {
      > * {
        flex: 1;
      }
      label {
        flex: 0;
        margin: 0 1rem 0 0;
      }
      max-width: 25rem;
      flex: 1;
      flex-wrap: no-wrap;
      flex-direction: row;
      display: flex;
      align-items: baseline;
      margin: 0.1rem 0.5rem;

      &.search-container {
        max-width: 15rem;
        min-width: 10rem;
      }
    }
    .filter-container-control {
      flex: 0;
      margin: 0.1rem 0.5rem;
    }
    .filter-container-spacer {
      flex: 1;
    }
  }
}