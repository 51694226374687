@import '../style/component_variables';

.PageTitle-container {
  .PageTitle-title {
    flex: 1;
  }
  .breadcrumb {
    padding: 0;
    line-height: 38px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 38px;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .PageTitle-accessories {
    > * {
      margin-right: 1rem;
    }

    margin-right: -1em;
  }
}

button.back-button {
  $background-color: white;
  $icon-color: $gray-900;
  $darken-percentage: 5%;

  background-color: $background-color;
  width: 2.5rem;
  border-radius: $card-border-radius;
  border: 1px solid $card-border-color;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: $icon-color;
  box-shadow: $shadow-sm;
  transition: all $transition-time;
  height: 100%;
  margin-right: 0.75rem;

  &:hover,
  &:focus {
    background-color: darken($background-color, $darken-percentage);
    text-decoration: none;
    color: darken($icon-color, $darken-percentage);
    box-shadow: $shadow-xs;
  }
}
