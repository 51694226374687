// Add this to your <Select>:
// className="bootstrap-react-select"
// classNamePrefix="bootstrap-react-select"
.bootstrap-react-select {
  &.bootstrap-react-select-transparent {
    .bootstrap-react-select__control {
      color: inherit;
      .bootstrap-react-select__single-value {
        color: inherit;
      }
      background-color: transparent;
      border-color: transparent;
      color: inherit;
      &.bootstrap-react-select__control--is-focused {
        color: inherit;
        // background-color: inherit;
        // border-style: solid;
        &:hover {
          border-color: $input-focus-border-color;
        }
      }
      .bootstrap-react-select__indicators {
        .bootstrap-react-select__indicator-separator {
          opacity: 0;
        }
      }

      &:hover {
        border-color: transparent;
      }
    }
  }
  .bootstrap-react-select__control {
    border-radius: $input-border-radius;
    background-color: $input-focus-bg;
    border-color: $input-border-color;
    .bootstrap-react-select__single-value {
      color: $input-color;
    }
    &.bootstrap-react-select__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none !important;
      z-index: 4 !important;
    }
    &.bootstrap-react-select__control--is-focused {
      z-index: 4 !important; // Reduce the amount of box shadow flicker
      // This is copied from bootstrap _forms mixin
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
      }

      &:hover {
        border-color: $input-focus-border-color;
      }
    }

    &.bootstrap-react-select__control--is-disabled {
      background-color: $input-disabled-bg;
      border-color: $input-border-color;
    }

    &:hover {
      border-color: $input-border-color;
    }

    .bootstrap-react-select__input {
      color: $input-color;
    }
  }

  .bootstrap-react-select__menu {
    z-index: 3 !important;
    // If the border causes problems, move it to the bxo sadhwo
    // box-shadow: 0 0 0 $input-border-width $input-focus-border-color, $shadow-sm !important;
    // box-shadow: $input-focus-box-shadow !important;
    box-shadow: $shadow-sm !important;
    // border-radius: $input-border-radius !important;
    border-bottom-left-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-style: solid !important;
    border-color: $input-focus-border-color !important;
    border-width: 1px !important;
    margin-top: -1px !important;
    .bootstrap-react-select__menu-list {
      // border-radius: $input-border-radius !important;
      border-bottom-left-radius: $input-border-radius !important;
      border-bottom-right-radius: $input-border-radius !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      background-color: $input-bg;
      .bootstrap-react-select__option {
        color: $input-color;

        &.bootstrap-react-select__option--is-selected {
          background-color: theme-color("primary");
          color: color-yiq(theme-color("primary"));

          &.bootstrap-react-select__option--is-focused {
            background-color: theme-color("primary");

            &:hover,
            &:active {
              background-color: darken(theme-color("primary"), 7.5%);
            }
          }

          &:hover,
          &:active {
            background-color: theme-color("primary");
          }
        }

        &.bootstrap-react-select__option--is-focused {
          &,
          &:hover,
          &:active {
            background-color: $table-hover-bg;
          }
        }

        &:hover,
        &:active {
          background-color: $table-hover-bg;
        }
      }
    }
  }
}
