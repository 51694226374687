@import "../style/component_variables";

.LoadingIndicator.loading {
  font-size: 32px;
  line-height: inherit;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .loading-indicator-children {
    margin-top: 16px;
    width: 100%;
    flex: 0;
  }

  .loading-indicator-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 4em;

    .loading-indicator {
      display: inline-block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: rgba(#000, 0.5);
      animation-name: loading-dots-animation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      opacity: 0.2;
    }

    .loading-indicator1 {
      animation-delay: 0s;
    }
    .loading-indicator2 {
      animation-delay: 0.2s;
    }
    .loading-indicator3 {
      animation-delay: 0.4s;
    }
  }
  @keyframes loading-dots-animation {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.2;
    }
  }
}

[data-theme="dark"] {
  .LoadingIndicator.loading {
    .loading-indicator {
      background-color: rgba(#fff, 0.3);
    }
  }
}
